<template>
  <base-list :items="items" />
</template>

<script>
import {
  genAddItem,
  genDefaultItem,
  genMenuItemUnlink
} from '@/utils/list-generators';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { computed, inject, onMounted, ref } from 'vue-demi';
import { objectGeoService, objectService } from '@/modules/objects/api';
import { prepareObject } from '@/provider/utils';
import { colors } from '@/compositions/map/utils/data';
import { usePopup } from '@/compositions/popup';

export default {
  name: 'PlanCardGeo',
  setup() {
    const entity = inject('entity');
    const geotags = ref([]);
    const { getNextRoute } = useHistoryStack();
    const popup = usePopup();

    const fetchGeoObjects = async () => {
      geotags.value = (
        await objectGeoService.fetchGeotags(entity.value.id)
      ).map(geotag => prepareObject(geotag));
    };

    const genGeotagActions = geotag => [
      genMenuItemUnlink(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Unlink geotag?',
            onConfirm: async () => {
              await objectService.unlink(geotag.relationId);
              await fetchGeoObjects();
            }
          }
        });
      })
    ];

    const genGeotagItem = geotag => {
      return genDefaultItem({
        title: geotag.name,
        subTitle: '',
        icon: '$geotag',
        avatarColor: colors['default'],
        actions: genGeotagActions(geotag),
        to: getNextRoute({
          component: componentAliases.gt,
          props: {
            geotagId: geotag.id
          }
        })
      });
    };

    const addGeoObject = async () => {
      popup.open({
        component: () => import('./GeoObjectAdd.vue'),
        props: {
          objectId: entity.value.id
        },
        listeners: {
          confirm: () => fetchGeoObjects()
        }
      });
    };

    const items = computed(() => [
      ...geotags.value.map(i => genGeotagItem(i)),
      genAddItem(
        { title: 'Add' },
        {
          click: addGeoObject
        }
      )
    ]);

    onMounted(async () => {
      fetchGeoObjects();
    });

    return { items };
  }
};
</script>

<style></style>
